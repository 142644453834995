.policy-page{
    padding: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    transition: .5s ease-in-out;
}
.title-cont{
    color:"#345261";
    width: 100%;
    color:#345261;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-cont h1{
    position: relative;
    padding: 10px 0px;
    font-weight: 800;
    width: max-content;
}
.title-cont h1::after{
    content: '';
    position: absolute;
    background-color: #345261;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%; 
    height: 4px;
    border-radius: 10px;
    animation: showLine 0.5s ease-out forwards;
    font-size: bold !important;
}

@media screen and (max-width:600px) {
    .policy-page{
        padding: 30px;
    }
}
@media screen and (max-width:300px) {
    .policy-page{
        padding: 20px;
    }
}

@keyframes showLine {
    0% {
        width: 0; /* Start with no width */
        opacity: 0;
    }
    100% {
        width: 70%; /* Final width */
        opacity: 1; /* Fully visible */
    }
}