.work-card-cont{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.work-card{
    border: 1px solid #34526133;
    flex: 300px;
    padding: 10px 20px;
    height: auto;
    min-width: auto;
    border-radius: 15px;
    transition: .5s ease-in-out;
}
.works-img{
    width:150px;
    height: 150px;
    object-position: center;
    object-fit: contain;
    transition: .5s ease-in-out;
}
.builder{
    width: 240px;
}
.title {
    font-weight: bold;
    color: #345261;
    margin-top: 10%;
    width: 100%;
    animation: moveUp 0.5s ease-out linear;
  }

@keyframes moveUp {
0% {
    transform: translateY(40px);
}

100% {
    transform: translateY(0);
}
}

@keyframes fadeIn {
    0%,
    60% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}

@media screen and (min-width:1450px) {
    .work-card{
        flex: 350px;
    }
}
