
.footer-top {
    display: flex;
    gap: px;
    transition: .5s ease-in-out;
}
.footer-cont{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 60%;
    transition: .5s ease-in-out;
}
.footer-second-cont{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 40%;
    transition: .5s ease-in-out;
}
.img{
    height:60px ;
    width:70%;
    transition: .5s ease-in-out;
}

.footer-bottom {
background-color: #7C7C7C;
height: 1px;
width: 100%;
}

.footer-bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    transition: .5s ease-in-out;
}
  

.footerlogo{
    text-align: justify;
    justify-content: start;   
    margin: 0px;
    
}
.spableabout{
    text-align: justify;
    -moz-text-align-last: start;
    text-align-last: start;
}
.footer {
    /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
    padding: 60px 60px 0px 60px;
    background-color: #345261;
}


.icons1 {
    font-size: 40px;
  
 
}

.icons2 {
    font-size: 40px;

    margin-left: 20px;

}

.icons3 {
    font-size: 40px;

    margin-left: 20px;

}

.icons4 {
    font-size: 35px;

    margin-left: 20px;

}
.footerend{
    background-color: rgba(32, 53, 63, 1);
  padding-top: 20px;
  padding-bottom: 20px;
}
.second-col{
    width: 55%;
}
.info-col{
    width: 55%;
}
.text{
    width:"80%";
    transition: .5s ease-in-out; 
}
.privacy-cont{
    display: flex;
    gap: 20px;
    color: #CECECE;
    cursor: pointer;
    transition: .5s ease-in-out; 
}
.privacy-cont p{
    margin: 0px;
    transition: .5s ease-in-out; 
}
.links{
    color: #CECECE;
    transition: .5s ease-in-out; 
}

.privacy-cont p:hover,
.links:hover{
    color: white;
}
.foot-info{
    flex: 70%;
}
.foot-quick-links{
    flex: 30%;
}

@media screen and (min-width:1500px) {
    .footer{
        padding: 60px 100px 0px 100px;
    }
    .text{
        width: 80%;
    }
    .foot-quick-links{
        flex: 20%;
    }
    .foot-info{
        width: 80%;
    }
    .img{
        width: 300px;
        object-fit: contain;
    }
}

@media screen and (min-width:1700px) {
    .second-col{
        width: 80%;
    }
    .info-col{
        width: 75%;
    }
}

@media screen and (max-width:1100px) {
    .footer-top {
        flex-direction: column;
        gap: 20px;
    }
    .footer-second-cont,
    .footer-cont {
        justify-content: space-between;
        flex: 0;
    }
    .img{
        width: 60%;
    }
    .second-col{
        width: 50%;
    }
    .info-col{
        width: 50%;
    }
}

@media screen and (max-width:900px) {
    .footer-bottom-content{
        flex-direction: column;
        gap: 20px;
    } 
}

@media screen and (max-width:800px) {
    .img{
        width: 70%;
    }
}

@media screen and (max-width:650px) {
    
    .img{
        width: 80%;
    }
    .footer-cont,
    .footer-second-cont{
        flex-direction: column;
        gap: 30px;
    }
    .footer-top{
        gap: 30px;
    }
    .info-col,
    .second-col,
    .contact-div{
        width: 100%;
    }
    .footer{
        padding: 40px 20px 20px 20px;
    }
    .privacy-cont{
        width: 100%;
        flex-direction: column;
        gap: 10px;
        align-items: start;
    }
}

@media screen and (max-width:400px) {
    .text{
        width: 100%;
    }
    .img{
        width: 100%;
    }
    .footer-cont{
        flex-direction: column;
        gap: 30px;
    }
    .footer-top{
        gap: 30px;
    }
    .footer{
        padding: 20px 10px 20px 10px;
    }
}